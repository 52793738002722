import React, { useEffect, useState, useMemo, useRef } from "react";
import classNames from "classnames";
import styles from "./ApplicationProcessingStep.module.scss";
import ApplicationProcessingTimeItem from "components/UI/ApplicationProcessingTimeItem/ApplicationProcessingTimeItem";
import {
    FAILURE_PROTECTION,
    next,
    setInfo,
    setIsFormSubmitted,
    STATE_FEE,
} from "store/slices/visaSteps";
import { useDispatch, useSelector } from "react-redux";
import { selectInfo, selectStepData } from "store/selectors/visaSteps";
import { selectTariffs } from "store/selectors/catalog";
import { getTariffPrice, getTariffsFlight } from "utils/tariffService";
import { selectCurrency } from "store/selectors/header";

const ApplicationProcessingStep = () => {
    const { countryTo, citizenship, visaType, tariff } = useSelector(selectInfo);
    const currentCurrency = useSelector(selectCurrency);
    const tariffData = useSelector(selectTariffs);
    const dispatch = useDispatch();
    const { isFormSubmitted } = useSelector(selectStepData);
    const isInitialMount = useRef(true);

    const availableTariffs = useMemo(() => {
        try {
            const bookingTicketTariffs = getTariffsFlight(tariffData);
            return bookingTicketTariffs
                .filter(
                    (item) =>
                        item.tariff_type === "VISA_ORDER" &&
                        citizenship?.code === item.citizenship?.code &&
                        countryTo?.code === item.country_to?.code &&
                        item?.visa_type?.name === visaType
                )
                .map((item) => ({
                    id: item.id,
                    title: item.name,
                    time: item.term,
                    imgSrc: item.icon,
                    description: item.description,
                    unit: item.unit,
                    price: getTariffPrice(currentCurrency?.code, item),
                }))
                .sort((a, b) => a.price - b.price);
        } catch (error) {
            console.error("Ошибка при получении тарифов:", error);
            return [];
        }
    }, [tariffData, citizenship, countryTo, visaType, currentCurrency]);

    const [checkedItem, setCheckedItem] = useState(tariff?.id);

    // Автоматический выбор первого тарифа, если ничего не выбрано (только при первой загрузке)
    useEffect(() => {
        if (isInitialMount.current && availableTariffs.length > 0 && !checkedItem) {
            const firstTariff = availableTariffs[0];
            setCheckedItem(firstTariff.id);
            dispatch(setInfo({ tariff: firstTariff }));
            isInitialMount.current = false;
        }
    }, [availableTariffs, checkedItem, dispatch]);

    // Обновление выбранного тарифа
    useEffect(() => {
        const selectedTariff = availableTariffs.find(({ id }) => id === checkedItem);
        if (selectedTariff) {
            dispatch(setInfo({ tariff: selectedTariff }));
        }
    }, [checkedItem, availableTariffs, dispatch]);

    // Переход к следующему шагу
    useEffect(() => {
        if (isFormSubmitted) {
            dispatch(next());
            dispatch(setIsFormSubmitted(false));
        }
    }, [isFormSubmitted, dispatch]);

    return (
        <div className={styles.page}>
            <div className={styles.form}>
                {availableTariffs.length > 0 ? (
                    availableTariffs.map((tariff) => (
                        <ApplicationProcessingTimeItem
                            key={tariff.id}
                            isChecked={checkedItem === tariff.id}
                            setIsChecked={() => setCheckedItem(tariff.id)}
                            className={styles.card}
                            {...tariff}
                            currentCurrency={currentCurrency}
                            description={tariff?.description}
                            tariff={tariff}
                        />
                    ))
                ) : (
                    <p>Тарифы недоступны для выбранной страны, гражданства или типа визы.</p>
                )}
            </div>
        </div>
    );
};

export default ApplicationProcessingStep;
