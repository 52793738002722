import {Radio} from 'components/UI/Radio/Radio'
import styles from './ApplicationProcessingTimeItem.module.scss'
import {ReactSVG} from "react-svg";
import timeIcon from 'assets/icons/smallClock.svg'
import classNames from 'classnames';
import {formatNumber} from 'utils/numberFn';
import {pluralizeDay, pluralizeHour} from "../../../utils/tariffService";
import React from "react";
import TextVisaTatiff from "../Text/TextVisaTariff";

const ApplicationProcessingTimeItem = ({
                                           imgSrc,
                                           isChecked,
                                           setIsChecked,
                                           title,
                                           price,
                                           time,
                                           className,
                                           currentCurrency,
                                           description,
                                           tariff
                                       }) => {

    return <div className={classNames([styles.wrapper, {[styles.checked]: isChecked}, className])}
                onClick={setIsChecked}>
        <div className={styles.header}>
            <img className={styles.src} src={imgSrc} alt="rate"/>
            <Radio checked={isChecked}/>
        </div>
        <div className={styles.body}>
            <div className={styles.rate}>
                <p className={styles.title}>{title}</p>

                <p className={styles.price}>{formatNumber(price)} {currentCurrency.symbol} <span>за пассажира</span></p>
                <TextVisaTatiff
                    variant={"body14"} className={styles.description}
                    dangerouslySetInnerHTML={{ __html: description }}
                />
            </div>
            <div className={styles.timeWrapper}>
                <div className={styles.icon}>
                    <ReactSVG src={timeIcon} width={20} height={20}/>
                </div>
                <p className={styles.time}>
                    {time} {tariff?.unit === 'day' ? pluralizeDay(time) : pluralizeHour(time)}
                </p>
            </div>
        </div>
    </div>
}

export default ApplicationProcessingTimeItem
