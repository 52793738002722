import React, {useEffect, useState} from 'react'
import CustomButton from 'components/UI/CustomButton/CustomButton'
import TitleBanner from 'components/UI/TitleBanner/TitleBanner'
import styles from './InfoStep.module.scss'
import passportsImg from 'assets/img/passports.png'
import {useDispatch, useSelector} from "react-redux";
import DropdownCountry from "components/UI/DropdownInput/DropdownCountry";
import {setInfo, setIsFormSubmitted, tariffs} from 'store/slices/visaSteps';
import {useCheckVisaQuery} from 'services/visaSteps'
import Text from 'components/UI/Text/Text'
import {selectInfo, selectStepData} from 'store/selectors/visaSteps'
import VisaDropdownCountry from 'components/UI/VisaDropdownCountry/VisaDropdownCountry'
import {useFormik} from 'formik'
import classNames from 'classnames'
import {useNavigate} from 'react-router'
import {useFormSubmitted} from 'pages/VisaStepsPage/hooks'
import {ROUTES} from 'api/constants'
import Spinner from 'components/UI/Spinner/Spinner'
import {validate} from './validate'
import {Skeleton} from 'antd';
import {useParams} from "react-router-dom";
import {selectTariffs, selectVisaCountries} from "../../../../store/selectors/catalog";
import {getTariffPrice, pluralizeDay} from "../../../../utils/tariffService";

const Banner = () => {
    const {visa, is_visa, countryTo, citizenship, isLoading, stay_of} = useSelector(selectInfo);
    if (isLoading) {
        return <Skeleton className={styles.payment} loading={isLoading} active paragraph={{rows: 2}}/>;
    }

    const bannerData = [
        {
            title: 'Требуется виза',
            desc: `Электронная виза для граждан страны ${citizenship?.label} в страну ${countryTo?.label}.`,
            imgSrc: passportsImg,
            imgAlt: 'passports',
            isDark: true
        },
        {
            title: 'Виза не требуется',
            desc: `Виза на ${countryTo?.label} не требуется, если у вас есть паспорт ${citizenship?.label}.`,
            isPrimary: true
        },
    ];

    return <TitleBanner {...bannerData[/*visa !== '' && visa !== 'Visa not required' &&*/ is_visa === true ? 0 : 1]} />;
}

const getDropdownProps = (data) => ({
    value: data?.code,
    label: data?.name_ru
});


const Form = ({setIsLoading}) => {
    const dispatch = useDispatch();
    const params = useParams()?.params?.split('-');
    const {countryTo: countryToDefault, citizenship: citizenshipDefault, is_visa, stay_of} = useSelector(selectInfo);
    const [countryTo, setCountryTo] = useState(countryToDefault);
    const [citizenship, setCitizenship] = useState(citizenshipDefault);
    const navigate = useNavigate();
    const visaCountries = useSelector(selectVisaCountries);
    const tariffs = useSelector(selectTariffs);
    const defaultOptionToArr = visaCountries?.country_to;
    const {data: result, isFetching, error} = useCheckVisaQuery({
        citizenship: params[0].toUpperCase(),
        countryTo: params[1].toUpperCase(),
    });
    const [visaTypeOptions, setVisaTypeOptions] = useState([]);

    const formik = useFormik({
        initialValues: {
            citizenship: getDropdownProps(result?.data?.citizenship),
            countryTo: getDropdownProps(result?.data?.countryTo),
            visaType: '',
        },
        validate,
        validateOnChange: false,
        validateOnBlur: false,
        enableReinitialize: true,
    });

    useEffect(() => {
        const filteredTariffs = tariffs.filter(tariff =>
            (tariff.visa_type.name || "").includes(formik.values.visaType)
        );
        let filteredTariff;
        if (filteredTariffs.length > 0) {
            if (filteredTariffs[0]?.visa_type.stay_of > 0)
                filteredTariff = filteredTariffs[0]?.visa_type.stay_of + ' ' + pluralizeDay(filteredTariffs[0]?.visa_type.stay_of);
        }
        dispatch(setInfo({
            isLoading: isFetching,
            ...result?.data,
            countryTo: {...result?.data?.countryTo, ...getDropdownProps(result?.data?.countryTo)},
            citizenship: {...result?.data?.citizenship, ...getDropdownProps(result?.data?.citizenship)},
            visaType: formik.values.visaType,
            stay_of: filteredTariff ?? null
        }));
    }, [result, isFetching, formik.values, stay_of]);

    useEffect(() => {
        if (formik.values.citizenship?.value && formik.values.countryTo?.value) {
            navigate(
                ROUTES.STEPS.replace(
                    ':params',
                    `${formik.values.citizenship?.value.toLowerCase()}-${formik.values.countryTo?.value.toLowerCase()}`
                ),
                {replace: true}
            );
        }
    }, [formik.values]);

    useEffect(() => {
        let tmpVisaTpes = [];
        tariffs.map((item) => {
            if (
                item.tariff_type === 'VISA_ORDER' &&
                formik.values.citizenship?.value === item.citizenship?.code &&
                formik.values.countryTo?.value === item.country_to?.code
            ) {
                if (
                    item?.visa_type?.name &&
                    !tmpVisaTpes.find((i) => i.value === item?.visa_type?.name)
                ) {
                    tmpVisaTpes.push({
                        value: item?.visa_type?.name,
                        label: item?.visa_type?.name,
                        sort: item?.visa_type?.sort,
                    });
                }
            }
        });
        if (tmpVisaTpes.length > 0)
            tmpVisaTpes.sort((a, b) => (a.sort ?? Infinity) - (b.sort ?? Infinity));
        setVisaTypeOptions(tmpVisaTpes);
        if (tmpVisaTpes.length > 0 && !formik.values.visaType) {
            formik.setFieldValue('visaType', tmpVisaTpes[0].value);
        }
    }, [formik.values, tariffs]);

    useFormSubmitted(formik);

    if (isFetching) {
        return <Skeleton className={styles.form} loading={isFetching} active paragraph={{rows: 6}}/>;
    }

    return (
        <form className={styles.form}>
            <div>
                <VisaDropdownCountry
                    onChange={(value) => formik.setFieldValue('citizenship', value)}
                    value={formik.values.citizenship}
                    label={'Гражданство'}
                    containerClasses={styles.select}
                    classes={styles.width}
                    onSearch={(value) => formik.setFieldValue('citizenship', value)}
                    error={formik.errors.citizenship}
                />
                {!formik.errors.citizenship && (
                    <Text variant="body14" className={classNames([styles.note])}>
                        Обязательно выберите ваше гражданство
                    </Text>
                )}
            </div>

            <div>
                <VisaDropdownCountry
                    onChange={(value) => formik.setFieldValue('countryTo', value)}
                    value={formik.values.countryTo}
                    label={'Поездка в страну'}
                    containerClasses={styles.select}
                    classes={styles.width}
                    onSearch={(value) => formik.setFieldValue('countryTo', value)}
                    error={formik.errors.countryTo}
                    setOptions={defaultOptionToArr}
                />
                {!formik.errors.countryTo && (
                    <Text variant="body14" className={classNames([styles.note])}>
                        Обязательно выберите страну назначения
                    </Text>
                )}
            </div>

            {result?.data?.is_visa /*&& visaTypeOptions.length > 0*/ && (
                <DropdownCountry
                    onChange={(value) => {
                        formik.setFieldValue('visaType', value);
                    }}
                    value={formik.values.visaType}
                    label={'Тип визы'}
                    options={visaTypeOptions}
                />
            )}
        </form>
    );
};

const InfoStep = () => {
    const [isLoading, setIsLoading] = useState(false);

    const dispatch = useDispatch();
    const onNext = () => {
        dispatch(setIsFormSubmitted(true));
    };

    const {is_visa} = useSelector(selectInfo);

    return (
        <div className={styles.page}>
            <Banner/>
            <Form setIsLoading={setIsLoading}/>
            {is_visa && <CustomButton
                classes={styles.btn}
                onClick={onNext}
                disabled={isLoading}
                primary
            >
                {isLoading ? <Spinner/> : 'Начать подачу заявления'}
            </CustomButton>}
        </div>
    );
};

export default InfoStep;
